import { message } from "antd";
import axios from "axios";
import qs from "qs";

import { actions } from "../../store/authRedux/actions";

let store;
export const setUpInterceptorStore = (_store) => {
  store = _store;
};

const API = axios.create({
  baseURL: "https://api.darbfadak.ir/api",
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
  },
  // paramsSerializer: (params) => {
  //   return qs.stringify(params, {
  //     arrayFormat: "repeat",
  //   });
  // },
});

// API Request interceptor
API.interceptors.request.use(
  (config) => {
    const access_token = store.getState().auth.token;

    if (access_token) {
      config.headers["Authorization"] = `Bearer ${access_token}`;
      config.headers[`Accept-Language`] = "fa";
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// API respone interceptor
API.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (!error.response) {
      message.error("خطا در اتصال اینترنت");
    }

    // Remove token and redirect
    else if (error.response.status === 403 || error.response.status === 401) {
      store.dispatch(actions.logout());
    } else if (error.response.status === 404) {
      message.error("اطلاعات موردنظر یافت نشد");
    } else if (error.response.status === 500) {
      message.error("خطایی در سرور رخ داده است");
    } else if (error.response.status === 508) {
      message.error("خطا در اتصال به سرور");
    }

    return Promise.reject(error);
  }
);

export default API;
