import { API } from '../utils'

export const getDeliverers = async ({ params }) => {
  const response = API.get('/deliverers', { params })
  const data = await response
  return data
}

export const createDeliverer = async ({ body }) => {
    const response = API.post('/deliverers', body)
    const data = await response
    return data
  }