export function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export function removeEmptyFString(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v != null && v != "")
  );
}

export function sortByPosition(arr) {
  return arr?.sort((a, b) => parseFloat(a.position) - parseFloat(b.position));
}

export const getProjectProgress = (steps) => {
  if (steps.length > 0) {
    let completed = 0;
    steps.map((step) => {
      if (step.status == 3) {
        completed += 1;
      }
    });
    return parseInt((completed / steps.length) * 100);
  } else {
    return 0;
  }
};
