import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Header } from './partials'
import styles from './styles.module.css'

const Layout = ({ children }) => {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({ isAuthorized: auth.user != null }),
    shallowEqual,
  )

  return (
    <main className={styles.container}>
      {isAuthorized && <Header />}
      <div
        className={`${styles.content} container px-3 pb-4`}
        style={{ paddingTop: isAuthorized ? '76px' : '16px' }}
      >
        {children}
      </div>
    </main>
  )
}

export default Layout
