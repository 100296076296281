import { API } from '../utils'

export const getDashboardData = async () => {
  const response = API.get('/admin/dashboard')
  const data = await response
  return data
}

export const getDashboardActivities = async () => {
  const response = API.get('/admin/activities')
  const data = await response
  return data
}