import React, { useState } from "react";
import {
  MenuOutlined,
  UserOutlined,
  TeamOutlined,
  ClusterOutlined,
  SnippetsOutlined,
  AppstoreOutlined,
  LoadingOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Drawer, Dropdown, Menu, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { AuthApi } from "../../../services";
import { actions as authActions } from "../../../store/authRedux/actions";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem("داشبورد", "", <DashboardOutlined />),
  getItem("مشتریان", "customers", <UserOutlined />),
  getItem("کارکنان", "users", <TeamOutlined />),
  getItem("دسته بندی پروژه ها", "projectTemplates", <ClusterOutlined />),
  getItem("فاکتور ها", "factors", <SnippetsOutlined />),
  getItem("پروژه ها", "projects", <AppstoreOutlined />),
];

const Header = () => {
  const user = useSelector(({ auth }) => auth.user);

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutMutate = useMutation(AuthApi.logoutUser, {
    onSuccess: (res) => {
      message.success({ content: "با موفقیت خارج شدید" });
      dispatch(authActions.logout());
    },
    onError: (error) => {
      if (error.response.status == 400) {
        message.error({ content: "خطا در ثبت داده" });
      }
    },
  });

  const profileItems = [
    {
      key: "profile-item-1",
      label: <Link to={`/users/edit/${user.uuid}`}>پروفایل</Link>,
    },

    {
      key: "profile-item-4",
      danger: true,
      label: <span onClick={() => logoutMutate.mutate()}>خروج</span>,
    },
  ];

  return (
    <>
      <div
        className="w-100 py-2 px-4 bg-white shadow-sm position-fixed top-0 d-flex justify-content-between mx-auto"
        style={{ maxWidth: 900, zIndex: 1000 }}
      >
        <MenuOutlined width={32} size={32} onClick={() => setOpen(true)} />
        <Dropdown menu={{ items: profileItems }}>
          <div className="d-flex align-items-center gap-2">
            {`${user?.first_name} ${user.last_name}`}
            {logoutMutate.isLoading ? (
              <LoadingOutlined spin={true} />
            ) : (
              <Badge
                dot={true}
                color="green"
                status="processing"
                style={{
                  width: 12,
                  height: 12,
                  right: -5,
                  bottom: -6,
                  top: "unset",
                }}
              >
                <Avatar
                  src={user.avatar && user?.avatar?.original_url}
                  size="large"
                  icon={<UserOutlined />}
                />
              </Badge>
            )}
          </div>
        </Dropdown>
      </div>
      <Drawer
        title="منوی اصلی"
        placement={"right"}
        width={300}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Menu
          onClick={(e) => {
            navigate(e.key);
            setOpen(false);
          }}
          mode="inline"
          items={items}
        />
      </Drawer>
    </>
  );
};

export default Header;
