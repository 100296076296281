import React, { lazy } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import BasePage from './BasePage'
import PageLayout from './layout'

const AuthPage = lazy(() => import('./pages/Auth'))

const Routes = () => {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({ isAuthorized: auth.user != null }),
    shallowEqual,
  )
  return <PageLayout>{isAuthorized ? <BasePage /> : <AuthPage />}</PageLayout>
}

export default Routes
