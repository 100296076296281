import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { actionTypes } from './actions'

const initialUserState = {
  user: null,
  token: null,
}

export const reducer = persistReducer(
  {
    storage,
    key: 'fadakpress-auth',
    whitelist: ['user', 'token'],
  },
  (state = initialUserState, action) => {
    switch (action.type) {
      case actionTypes.LOGIN:
        return {
          ...state,
          user: action.payload.user,
          token: action.payload.token,
        }

      case actionTypes.SET_USER:
        return {
          ...state,
          user: action.payload,
        }

      case actionTypes.LOGOUT:
        return initialUserState

      default:
        return state
    }
  },
)
