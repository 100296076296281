import { API } from '../utils'

export const loginOtp = async ({ body }) => {
  const response = API.post('/auth/login-otp', body)
  const data = await response
  return data
}

export const checkOtp = async ({ body }) => {
  const response = API.post('/auth/check-otp', body)
  const data = await response
  return data
}

export const logoutUser = async () => {
  const response = API.put('/auth/logout')
  const data = await response
  return data
}
