import { API } from "../utils";

export const getCustomers = async ({ params }) => {
  const response = API.get("/customers", { params });
  const data = await response;
  return data;
};

export const getCustomersExcel = async ({ params }) => {
  const response = API.get("/customers/excel/export", { params });
  const data = await response;
  return data;
};

export const createCustomer = async ({ body }) => {
  const response = API.post("/customers", body);
  const data = await response;
  return data;
};

export const deleteCustomer = async ({ params }) => {
  const response = API.delete(`/customers/${params.id}`, { params });
  const data = await response;
  return data;
};

export const getSingleCustomer = async ({ params }) => {
  const response = API.get(`/customers/${params.id}`, { params });
  const data = await response;
  return data;
};

export const updateCustomer = async ({ params, body }) => {
  const response = API.patch(`/customers/${params.id}`, body);
  const data = await response;
  return data;
};

export const restoreCustomer = async ({ params }) => {
  const response = API.put(`/customers/${params.id}/restore`);
  const data = await response;
  return data;
};

export const uploadCustomerMedia = async ({ params, body, onUploadProgress }) => {
  const response = API.post(`/customers/${params.customer}/media`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
    timeout: 120000
  });
  const data = await response;
  return data;
};

export const deleteCustomerMedia = async ({ params }) => {
  const response = API.delete(
    `/customers/${params.customer}/media/${params.media}`
  );
  const data = await response;
  return data;
};
