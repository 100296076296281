import { API } from '../utils'

export const getUsers = async ({ params }) => {
  const response = API.get('/admin/users', { params })
  const data = await response
  return data
}

export const getUsersExcel = async ({ params }) => {
  const response = API.get('/admin/users/excel/export', { params })
  const data = await response
  return data
}

export const createUser = async ({ body }) => {
  const response = API.post('/auth/register', body)
  const data = await response
  return data
}

export const deleteUser = async ({ params }) => {
  const response = API.delete(`/admin/users/${params.id}`, { params })
  const data = await response
  return data
}

export const getSingleUser = async ({ params }) => {
  const response = API.get(`/admin/users/${params.id}`, { params })
  const data = await response
  return data
}

export const updateUser = async ({ params, body }) => {
  const response = API.patch(`/admin/users/${params.id}`, body)
  const data = await response
  return data
}

export const restoreUser = async ({ params }) => {
  const response = API.put(`/admin/users/${params.id}/restore`)
  const data = await response
  return data
}

export const uploadUserMedia = async ({ params, body, onUploadProgress }) => {
  const response = API.post(`/admin/users/${params.user}/media`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
    timeout: 120000
  })
  const data = await response
  return data
}

export const deleteUserMedia = async ({ params }) => {
  const response = API.delete(
    `/admin/users/${params.user}/media/${params.media}`
  );
  const data = await response;
  return data;
};