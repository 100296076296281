import { API } from '../utils'

export const getProjectTemplates = async ({ params }) => {
  const response = API.get('/project-templates', { params })
  const data = await response
  return data
}

export const createProjectTemplate = async ({ body }) => {
  const response = API.post('/project-templates', body)
  const data = await response
  return data
}

export const deleteProjectTemplate = async ({ params }) => {
  const response = API.delete(`/project-templates/${params.id}`, { params })
  const data = await response
  return data
}

export const getProjectTemplate = async ({ params }) => {
  const response = API.get(`/project-templates/${params.id}`, { params })
  const data = await response
  return data
}

export const updateProjectTemplate = async ({ params, body }) => {
  const response = API.patch(`/project-templates/${params.id}`, body)
  const data = await response
  return data
}

export const restoreProjectTemplate = async ({ params }) => {
  const response = API.put(`/project-templates/${params.id}/restore`)
  const data = await response
  return data
}
