export const actionTypes = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_USER: 'SET USER',
}

export const actions = {
  login: (data) => ({
    type: actionTypes.LOGIN,
    payload: data,
  }),
  logout: () => ({
    type: actionTypes.LOGOUT,
    payload: null,
  }),
  setUser: (user) => ({
    type: actionTypes.SET_USER,
    payload: user,
  }),
}
