import { Spin } from 'antd'
import React from 'react'

const SplashScreen = () => {
  return (
    <Spin
      spinning={true}
      className="w-100 h-100 d-flex justify-content-center align-items-center"
      size="large"
    ></Spin>
  )
}

export default SplashScreen
