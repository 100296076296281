import { API } from "../utils";

export const getFactors = async ({ params }) => {
  const response = API.get("/factors", {
    params,
    paramsSerializer: {
      indexes: false,
    },
  });
  const data = await response;
  return data;
};

export const getFactorsExcel = async ({ params }) => {
  const response = API.get("/factors/excel/export", { params });
  const data = await response;
  return data;
};

export const createFactor = async ({ body }) => {
  const response = API.post("/factors", body);
  const data = await response;
  return data;
};

export const deleteFactor = async ({ params }) => {
  const response = API.delete(`/factors/${params.id}`, { params });
  const data = await response;
  return data;
};

export const getFactor = async ({ params }) => {
  const response = API.get(`/factors/${params.id}`, { params });
  const data = await response;
  return data;
};

export const updateFactor = async ({ params, body }) => {
  const response = API.patch(`/factors/${params.id}`, body);
  const data = await response;
  return data;
};

export const restoreFactor = async ({ params }) => {
  const response = API.put(`/factors/${params.id}/restore`);
  const data = await response;
  return data;
};

export const updateFactorStatus = async ({ params, body }) => {
  const response = API.put(`/factors/${params.id}/status`, body);
  const data = await response;
  return data;
};

export const uploadFactorMedia = async ({ params, body, onUploadProgress }) => {
  const response = API.post(`/factors/${params.factor}/media`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
    timeout: 120000
  });
  const data = await response;
  return data;
};

export const deleteFactorMedia = async ({ params }) => {
  const response = API.delete(
    `/factors/${params.factor}/media/${params.media}`
  );
  const data = await response;
  return data;
};
