import { useJalaliLocaleListener } from 'antd-jalali'
import React, { lazy, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Routes as Switch, Route, Navigate } from 'react-router-dom'

const Customers = lazy(() => import('./pages/Customers'))
const Users = lazy(() => import('./pages/Users'))
const ProjectTemplates = lazy(() => import('./pages/ProjectTemplates'))
const Factors = lazy(() => import('./pages/Factors'))
const Projects = lazy(() => import('./pages/Projects'))
const Dashboard = lazy(() => import('./pages/Dashboard'))

const BasePage = () => {
  useJalaliLocaleListener()

  return (
    <Switch>
      <Route path="*" element={<Navigate to={'/'} replace />} />
      <Route path="/customers/*" element={<Customers />} />
      <Route path="/users/*" element={<Users />} />
      <Route path="/projectTemplates/*" element={<ProjectTemplates />} />
      <Route path="/factors/*" element={<Factors />} />
      <Route path="/projects/*" element={<Projects />} />
      <Route path="/" element={<Dashboard />} />
    </Switch>
  )
}

export default BasePage
