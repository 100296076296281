import React from "react";
import { Badge } from "antd";

const ProjectStatuses = [
  {
    id: 1,
    name: "انجام نشده",
    color: "#faad14",
    status: "warning",
  },
  {
    id: 2,
    name: "در حال انجام",
    color: "#1677ff",
    status: "processing",
  },
  {
    id: 3,
    name: "انجام شده",
    color: "#52c41a",
    status: "success",
  },
];
const FactorStatuses = [
  {
    id: 1,
    name: "انجام نشده",
    color: "#faad14",
    status: "warning",
  },
  {
    id: 2,
    name: "در حال انجام",
    color: "#1677ff",
    status: "processing",
  },
  {
    id: 3,
    name: "انجام شده",
    color: "#52c41a",
    status: "success",
  },
  {
    id: 4,
    name: "ارسال شده",
    color: "#9933cc",
    status: "sent",
  },
];

const StatusBadge = ({ status }) => {
  const currentStatus = FactorStatuses.filter((item) => item.id === status)[0];

  return (
    <div className="d-flex align-items-center">
      {status === 2 ? <Badge status="processing" className="mx-1" /> : null}
      <Badge count={currentStatus.name} color={currentStatus.color} />
    </div>
  );
};

export { FactorStatuses, ProjectStatuses, StatusBadge };
