import { API } from '../utils'

export const getProjects = async ({ params }) => {
  const response = API.get('/projects', { params })
  const data = await response
  return data
}

export const getProjectsExcel = async ({ params }) => {
  const response = API.get('/projects/excel/export', { params })
  const data = await response
  return data
}

export const createProject = async ({ body }) => {
  const response = API.post('/projects', body)
  const data = await response
  return data
}

export const deleteProject = async ({ params }) => {
  const response = API.delete(`/projects/${params.id}`, { params })
  const data = await response
  return data
}

export const getProject = async ({ params }) => {
  const response = API.get(`/projects/${params.id}`, { params })
  const data = await response
  return data
}

export const updateProject = async ({ params, body }) => {
  const response = API.patch(`/projects/${params.id}`, body)
  const data = await response
  return data
}

export const restoreProject = async ({ params }) => {
  const response = API.put(`/projects/${params.id}/restore`)
  const data = await response
  return data
}

export const updateProjectStatus = async ({ params, body }) => {
  const response = API.put(`/projects/${params.id}/status`, body)
  const data = await response
  return data
}

export const updateStepStatus = async ({ params, body }) => {
  const response = API.put(
    `/projects/${params.id}/steps/${params.step}/status`,
    body,
  )
  const data = await response
  return data
}

export const getProjectSteps = async ({ params }) => {
  const response = API.get(`/projects/${params.id}/steps`)
  const data = await response
  return data
}

export const updateProjectSteps = async ({ params, body }) => {
  const response = API.post(`/projects/${params.id}/steps`, body)
  const data = await response
  return data
}

export const deleteStep = async ({ params }) => {
  const response = API.delete(`/projects/${params.id}/steps/${params.step}`, {
    params,
  })
  const data = await response
  return data
}

export const updateStep = async ({ params, body }) => {
  const response = API.patch(
    `/projects/${params.id}/steps/${params.step}`,
    body,
  )
  const data = await response
  return data
}

export const uploadProjectMedia = async ({ params, body,  onUploadProgress}) => {
  try {
    const response = API.post(`/projects/${params.project}/media`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      timeout: 120000
    })
    const data = await response
    return data
  } catch (error) {
  }
}

export const deleteProjectMedia = async ({ params }) => {
  const response = API.delete(
    `/projects/${params.project}/media/${params.media}`
  );
  const data = await response;
  return data;
};
