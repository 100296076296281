import 'bootstrap/dist/css/bootstrap.css'
import 'react-loading-skeleton/dist/skeleton.css'

import { ConfigProvider } from 'antd'
import React, { Suspense, useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import Routes from './Routes'
import { store, persistor } from './store/store'

import { QueryClient, QueryClientProvider } from 'react-query'
import fa_IR from 'antd/es/locale/fa_IR'

import { setUpInterceptorStore } from './utils/API'

import { SplashScreen } from './components'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false, refetchOnWindowFocus: false },
  },
})

const App = () => {
  // set up store for axios interceptors
  setUpInterceptorStore(store)

  return (
    // Redux State Management Provider
    <Provider store={store}>
      {/* Redux Persistor Gate */}
      <PersistGate persistor={persistor} loading={<SplashScreen />}>
        {/* Splash Screen Fallback */}
        <Suspense fallback={<SplashScreen />}>
          {/* Query Client Provider */}
          <QueryClientProvider client={queryClient}>
            {/* Ant Design Theme Provider */}
            <ConfigProvider
              prefixCls="ant"
              direction="rtl"
              locale={fa_IR}
              theme={{
                token: {
                  fontFamily: 'iranyekan',
                },
              }}
            >
              {/* React Router */}
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </ConfigProvider>
          </QueryClientProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  )
}

export default App
